import React,{ useEffect }  from 'react'

export default function ElectricianPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div><>
    <section id="breadcrumbs" className="breadcrumbs">
      <div className="container">
        <ol>
          <li>
            <a href="index.html">Home</a>
          </li>
          <li>Electrician</li>
        </ol>
        <h2>Electrician</h2>
      </div>
    </section>
    {/* End Breadcrumbs */}
    <section className="inner-page">
      <div className="container">
        <div className="row">
          <div
            className="col-lg-7 col-xs-12 order-2 order-lg-1"
            style={{ paddingRight: 30 }}
          >
            <div className="res-991-pt-0" >
              <h3 className="text-primary"><strong>Electrician</strong></h3>
              <p style={{ textAlign: "justify" }}>
              
<strong>Course Information:</strong> <br />This course is meant for the candidate who aspires to become Technician in ELECTRICIAN.
Terminal Competency/Deliverables:
The well-equipped electrical labs provide an excellent opportunity to the students for developing in depth skills in Electrical Engineering. Our labs are equipped with latest equipment’s and different types of Motors and Generators. Also they learn Electrical Wiring, different types of generating station, Transmission and Distribution of Power,
 Motor Rewinding, Electrical Panel Boards, handling of different types of electrical measuring instruments, study of Relays and circuit breakers, Working principles of different types of AC & DC Motors, Generators and Transformers, Repair and maintenance of Electrical appliances. They also learn the working and uses of basic Electronics components and equipment’s<br />

<strong>Further opportunities after ITI:</strong><br />
Candidates after completing ITI have both academic and Job oriented prospects to continue with.<br />

Immediate Job opportunity after completion of the course
One-year Apprenticeship training with stipend (above Rs. 10,000 /- per month) in any Public / Private sectors / MNC companies
Contract License Certificate in State Electricity Boards
Diploma lateral entry to 3rd semester (2nd Year)
Can opt for CITS (Craftsmen Instructor Training Scheme) Course
Job in Public / Private sectors / MNC companies & Abroad also
Huge job opportunities in power generation, transmission, distribution station.
Huge abroad job opportunities
Self-Employment.

              </p>
              <p>
                
              </p>
            </div>
          </div>
          <div className="col-lg-5 col-xs-12 order-1 order-lg-2">
            {/* ttm_single_image-wrapper */}
            <div className="ttm_single_image-wrapper text-right d-flex align-items-end pb-5" style={{paddingTop:'100px'}}>
              <img
                style={{
                  padding: 5,
                  boxShadow: "0px 0px 10px #CCC",
                  border: "1px solid #CCC"
                }}
                className="img-fluid"
                src="assets/img/ele.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
  </div>
  )
}
