import React,{ useEffect }  from 'react'

export default function WelderPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div><>
    <section id="breadcrumbs" className="breadcrumbs">
      <div className="container">
        <ol>
          <li>
            <a href="index.html">Home</a>
          </li>
          <li>Welder</li>
        </ol>
        <h2>Welder</h2>
      </div>
    </section>
    {/* End Breadcrumbs */}
    <section className="inner-page">
      <div className="container">
        <div className="row">
          <div
            className="col-lg-7 col-xs-12 order-2 order-lg-1"
            style={{ paddingRight: 30 }}
          >
            <div className="res-991-pt-0" >
              <h3 className="text-primary"><strong>Welder</strong></h3>
              <p style={{ textAlign: "justify" }}>
              Knowledge in welding of joints uniformly by gas and arc welding, Weld ferrous and Non-ferrous metals to the requited standards and specialised welding like TIG, MIG, Plasma, Spot and Projection welding are imparted in this trade.<br />

<strong>WELDER CAREER PROGRESSION PATHWAYS – ITI</strong><br />
• Can join industry as Technician and will progress further as Senior Technician, Supervisor and can rise up to the level of Manager.<br />

• Can become Entrepreneur in the related field.<br />

• Can join Apprenticeship programme in different types of industries leading to National Apprenticeship certificate (NAC).<br />

• Can join Crafts Instructor Training Scheme (CITS) in the trade for becoming instructor in ITIs.<br />

• Can join Advanced Diploma (Vocational) courses under DGT as applicable.<br />

<strong>WELDER JOB ROLE – ITI</strong><br />

Welder, Gas; fuses metal parts together using welding rod and oxygen acetylene flame. Examines parts to be welded, cleans portion to be joined, holds them together by some suitable device and if necessary, makes narrow groove to direct flow of molten metal to strengthen joint. Selects correct type and size of welding rod, nozzle etc. and tests welding, torch. Wears dark glasses and other protective devices while welding. Releases and regulates valves of oxygen and acetylene cylinders to control their flow into torch. Ignites torch and regulates flame gradually. Guides flame along joint and heat it to melting point, simultaneously melting welding rod and spreading molten metal along joint shape, size etc. and rectifies defects if any.
Welder, Electric; fuses metals using arc-welding power source and electrodes. Examines parts to be welded, cleans them and sets joints together with clamps or any other suitable device. Starts welding power source and regulates current according to material and thickness of welding. Connect one lead to part to be welded, selects required type of electrode and clamps other lead to electrode holder. May join parts first at various points for holding at specified angles, shape, form and dimension by tack welding. Establish arc between electrode and joint and maintain it throughout the length of the joint.<br />
<strong>Welder Entry Qualification & Course Durations – ITI</strong><br />

• Passed 8th class examination<br />

• 1year.<br />




              </p>
              <p>
                
              </p>
            </div>
          </div>
          <div className="col-lg-5 col-xs-12 order-1 order-lg-2">
            {/* ttm_single_image-wrapper */}
            <div className="ttm_single_image-wrapper text-right d-flex align-items-end pb-5" style={{paddingTop:'200px'}}>
              <img
                style={{
                  padding: 5,
                  boxShadow: "0px 0px 10px #CCC",
                  border: "1px solid #CCC"
                }}
                className="img-fluid"
                src="assets/img/Welder.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
  </div>
  )
}
