import React from 'react'

export default function Contact() {
  return (
    <div>     <section id="contact" className="contact section-bg">
    <div className="container" data-aos="fade-up">
      <div className="section-title">
        <h2>Contact</h2>
        <p>Contact Us</p>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="row">
            <div className="col-md-12">
              <div className="info-box">
                <i className="bx bx-map" />
                <h3>Our Address</h3>
                <p>
                Opp:Starzen Hospital,<br />Yelamanchili Road,Atchutapuram,<br />Anakapalli- 531011<br /><br /></p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="info-box mt-4">
                <i className="bx bx-envelope" />
                <h3>Email Us</h3>
                <p>info@example.com<br />contact@example.com</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="info-box mt-4">
                <i className="bx bx-phone-call" />
                <h3>Call Us</h3>
                <p>7702252510<br />6281080676</p>
              </div>
            </div>
          </div>
        </div>
        <div
  className="col-md-6 aos-init aos-animate"
  data-aos="fade-right"
  data-aos-offset={300}
  data-aos-easing="ease-in-sine"
>
  <div className="contact-page-map">
    <iframe
      src="https://maps.google.com/maps?q=Opp:Starzen Hospital, Yelamanchili Road,Atchutapuram, Anakapalli- 531011&t=&z=10&ie=UTF8&iwloc=&output=embed"
      width="100%"
      height={400}
      frameBorder={0}
      style={{ border: 0, borderRadius: 5 }}
      allowFullScreen=""
    />
  </div>
</div>

      </div>
    </div>
  </section></div>
  )
}
