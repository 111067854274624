import React,{ useEffect }  from 'react'
import About from '../Components/About'

export default function AboutPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
        <About />
    </div>
  )
}
