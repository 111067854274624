import React from 'react'

export default function AboutUs() {
  return (
  //   <div>      <section id="portfolio" className="portfolio">
  //   <div className="container" data-aos="fade-up">
  //     <div className="section-title">
  //       <h2>government affiliation</h2>
  //       <p>TOWARDS INTEGRAL GROWTH</p>
  //     </div>

  //     <div className="row portfolio-container" data-aos="fade-up" data-aos-delay={200}>
        
  //       <div className="col-lg-4 col-md-6 portfolio-item filter-web">
  //         <img src="assets/img/Skill-india.png" className="img-fluid" alt="" />
  //         <div className="portfolio-info">
  //           <h4>SKILL INDIA</h4>
  //           <p>Designer</p>
  //           <a href="assets/img/portfolio/portfolio-2.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="Web 3"></a>
  //           <a href="portfolio-details.html" className="details-link" title="More Details"><i className="bx bx-link" /></a>
  //         </div>
  //       </div>
  //       <div className="col-lg-4 col-md-6 portfolio-item filter-app">
  //         <img src="assets/img/ITI.png" className="img-fluid" alt="" />
  //         <div className="portfolio-info">
  //           <h4>INDUSTRIAL TRAINING INSTITUTE</h4>
  //           <p></p>
  //           <a href="assets/img/portfolio/portfolio-3.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="App 2"></a>
  //           <a href="portfolio-details.html" className="details-link" title="More Details"><i className="bx bx-link" /></a>
  //         </div>
  //       </div>
  //       <div className="col-lg-4 col-md-6 portfolio-item filter-app">
  //         <img src="assets/img/courses.png"  className="img-fluid" alt=""  style={{ width:'50%' , paddingTop:'15px'}}/>
  //         <div className="portfolio-info">
  //           <h4>Sri RamaKrishna ITI College</h4>
  //           <p>About Us</p>
  //           <a href="assets/img/portfolio/portfolio-1.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="App 1"></a>
  //           <a href="portfolio-details.html" className="details-link" title="More Details"><i className="bx bx-link" /></a>
  //         </div>
  //       </div>
 
  //     </div>
  //   </div>
  // </section></div>
  <div>
    <div>   {/* ======= Services Section ======= */}
    <section id="services" className="services">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>TOWARDS INTEGRAL GROWTH</h2>
          <p>Government Affiliation</p>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay={100}>
            <div className="icon-box">
            <img src="assets/img/Skill-india.png" className="img-fluid" alt="" />
            <hr />
              <h4><a href>SKILL INDIA</a>
              <p>Designer</p></h4>
              <p>The National Skill Development Agency, invites innovative ideas, concepts and practices on skill development. A committee has been set up to review all such innovations and to facilitate their application on a wider scale.</p>
              <a class="custom-btn btn-3" href='https://www.skilldevelopment.gov.in/' target='_blank'><span style={{lineHeight:'2.5'}}>Know More</span></a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay={200}>
            <div className="icon-box">
            <img src="assets/img/ITI.png" className="img-fluid" alt="" />
            <hr />
              <h4><a href>INDUSTRIAL TRAINING INSTITUTE</a></h4>
              <p>Industrial Training Institutes constituted under Directorate General of Employment & Training (DGET), Ministry of Skill Development and Entrepreneurship, Union Government to provide training in various trades.</p>
              <a class="custom-btn btn-3" href='http://dget.nic.in/content/institute/industrial-training-institutes-itis.php' target='_blank'><span style={{lineHeight:'2.5'}}>Know More</span></a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay={300}>
            <div className="icon-box">
            <img src="assets/img/courses.png"  className="img-fluid" alt="" />
          <hr />
              <h4><a href>COURSES List</a>
              <p>our courses</p></h4>
              <p>
              Fitter cum Blacksmith. (NCVT) (2 years) 10th Pass <br />
              Electrician. (NCVT)(2 years) 10th Pass<br />
              Welder. (NCVT)(2 years) 10th Pass<br /></p>
              <a class="custom-btn btn-3" href='/Fitter' target='_blank'><span style={{lineHeight:'2.5'}}>Fitter</span></a>
              <a class="custom-btn btn-3" href='/Electrician' target='_blank'><span style={{lineHeight:'2.5'}}>Electrician</span></a>
              <a class="custom-btn btn-3" href='/Welder' target='_blank'><span style={{lineHeight:'2.5'}}>Welder</span></a>
            </div>
          </div>
         
        </div>
      </div>
    </section>{/* End Services Section */}</div>
  </div>
  )
}
