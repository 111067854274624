import React,{ useEffect }  from 'react'
import Contact from '../Components/Contact';
import Courses from '../Components/Courses';
import Footer from '../Components/Footer';
import About from '../Components/About';
import Hero from "../Components/Hero";
import Portfolio from '../Components/Portfolio';
import Topbar from '../Components/Topbar';
import Headerlogo from '../Components/Headerlogo';
import Whyus from '../Components/Whyus';
import AboutUs from '../Components/AboutUs';


export default function HomePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
       {/* <div style={{ paddingTop: '50px' }}> */}
        
        <Hero />
        {/* <Whyus /> */}
        <About />
       {/* <Courses /> */}
       <AboutUs />
       <Contact />
       
       
       {/* </div> */}
    </div>
  )
}
