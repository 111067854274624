import React,{ useEffect }  from 'react'

export default function FitterPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div><>
    <section id="breadcrumbs" className="breadcrumbs">
      <div className="container">
        <ol>
          <li>
            <a href="index.html">Home</a>
          </li>
          <li>Fitter</li>
        </ol>
        <h2>Fitter</h2>
      </div>
    </section>
    {/* End Breadcrumbs */}
    <section className="inner-page">
      <div className="container">
        <div className="row">
          <div
            className="col-lg-7 col-xs-12 order-2 order-lg-1"
            style={{ paddingRight: 30 }}
          >
            <div className="res-991-pt-0" >
              <h3 className="text-primary"><strong>Fitter</strong></h3>
              <p style={{ textAlign: "justify" }}>
              
             
<strong>Course Information:</strong><br /> This course is meant for the candidates who aspire to become a professional fitter.<br />
<strong>Terminal Competency/Deliverables:</strong><br />
After successful completion of this course the trainee can work in the industry as semi-skilled fitter
Fitter trainees are trained in Basic Fittings, Measuring Instruments, Handling of different types of Hand tools, Lathe operations, Welding, Sheet metals, Advance fittings, Hydraulics and Pneumatics, Assembly and study of machine drawings etc. It is a mechanical course having huge demand in public sectors, private sectors and MNC Companies.<br />
<strong>Employment Opportunities:</strong><br />
On successful completion of this course, the candidates shall be gainfully employed in the following industries
Production & Manufacturing industries
Structural Fabrication like bridges, Roof structures, Building & construction
Automobile and allied industries
Service industries like road transportation and Railways
Ship building and repair
Infrastructure and Defence organizations
In public sector industries like BHEL, BEML, HAL, DRDO, NTPC etc. and private industries in India & abroad
Self-Employment<br />
<strong>Further Opportunities after ITI:</strong><br />
Candidates after completing ITI have both academic and Job oriented prospects to continue with

Immediate Job opportunity after completion of the course
One year Apprenticeship training with stipend (above Rs.10,000 /- per month) in any Public / Private sectors / MNC companies
Diploma lateral entry to 3rd semester (2nd Year)
Can opt for CITS (Craftsmen Instructor Training Scheme) Course
Job in Public / Private sectors / MNC companies & Abroad also
Self-Employment

              </p>
              <p>
                
              </p>
            </div>
          </div>
          <div className="col-lg-5 col-xs-12 order-1 order-lg-2">
            {/* ttm_single_image-wrapper */}
            <div className="ttm_single_image-wrapper text-right d-flex align-items-end pb-5" style={{paddingTop:'150px'}}>
              <img
                style={{
                  padding: 5,
                  boxShadow: "0px 0px 10px #CCC",
                  border: "1px solid #CCC"
                }}
                className="img-fluid"
                src="assets/img/fit.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
  </div>
  )
}
