import React, { useState, useEffect } from 'react';

export default function Hero() {
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    const carouselInterval = setInterval(() => {
      // Increment the active slide index
      setActiveSlide((prevSlide) => (prevSlide + 1) % 3);
    }, 5000);

    // Clean up the interval when the component 
    return () => {
      clearInterval(carouselInterval);
    };
  }, []);

  return (
    <div>
      <section id="hero">
        <div id="heroCarousel" data-bs-interval={5000} className="carousel slide carousel-fade" data-bs-ride="carousel">
          <ol className="carousel-indicators" id="hero-carousel-indicators" />
          <div className="carousel-inner" role="listbox">
            {/* Slide 1 */}
            <div className={`carousel-item ${activeSlide === 0 ? 'active' : ''}`} style={{backgroundImage: 'url(assets/img/slide/s1.jpg)'}}>
              {/* Slide 1 content */}
            </div>
            {/* Slide 2 */}
            <div className={`carousel-item ${activeSlide === 1 ? 'active' : ''}`} style={{backgroundImage: 'url(assets/img/slide/s2.jpg)'}}>
              {/* Slide 2 content */}
            </div>
            {/* Slide 3 */}
            <div className={`carousel-item ${activeSlide === 2 ? 'active' : ''}`} style={{backgroundImage: 'url(assets/img/slide/s1.jpg)'}}>
              {/* Slide 3 content */}
            </div>
          </div>
          
        </div>
      </section>
    </div>
  );
}
