import React from 'react'

export default function Topbar() {
  return (
  <div>
      <div className="marque-wrapper">
      <marquee behavior="scroll" direction="left">
        <span className="text-white">Welcome to Sri RamaKrishna ITI College</span>
      </marquee>
    </div>
    
  </div>
  )
}


