import React from 'react'

export default function About() {
  return (
    <div>          <section id="about" className="about">
    <div className="container" data-aos="fade-up">
      <div className="section-title">
        <h2>About</h2>
        <p>WELCOME TO <strong>Ramakrishna ITI </strong></p>
      </div>
      <div className="row content">
        <div className="col-lg-12">
          <p>
          The Ramakrishna ITI  was established in the year 2013  with  the main objective to 
          provide academic excellence to all sections of the society. The trust focus on imparting quality education, disciplined integrated personality development,
          research, management skills to face the global challenges.The Ramakrishna Industriual Training Institute (ITI) was established in 2013 .
        with three trades FITTER electriian & welder. 
        Our Chairman has started this ITI to provide employment to economically poor background class students, who due to various reasons, are unable to pursue 
        further studies after SSC.
          </p>
      
        </div>
        {/* <div className="col-lg-12 pt-4 pt-lg-0">
          <p>
           
        The Ramakrishna Industriual Training Institute (ITI) was established in 2013 .
        with three trades FITTER electriian & welder. 
        Our Chairman has started this ITI to provide employment to economically poor background class students, who due to various reasons, are unable to pursue 
        further studies after SSC.
        // <h3 style={{fontWeight:'400',fontSize:'34px',color:'#123a6d'}}>WELCOME TO <strong>Ramakrishna ITI </strong></h3>
          </p>
       
        </div> */}
      </div>
    </div>
  </section>
    {/* ======= Counts Section ======= */}
   {/* ======= Counts Section ======= */}
    <div style={{ paddingTop: '50px' }}>
    <section id="counts" className="counts">
            <div className="container" data-aos="fade-up">
              <div className="row no-gutters">
                <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
                  <div className="count-box">
                   <img src='assets/img/quality.png'   style={{width:'25%'}}/>
                    <span data-purecounter-start={0} data-purecounter-end={232} data-purecounter-duration={1} className="purecounter" />
                    <p><strong>Quality Technical Education</strong></p>
                  <br />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
                  <div className="count-box">
                  <img src='assets/img/ind.png'   style={{width:'25%'}}/>
                    <span data-purecounter-start={0} data-purecounter-end={521} data-purecounter-duration={1} className="purecounter" />
                    <p><strong>Best Industrial Exposure</strong></p>
                  <br />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
                  <div className="count-box">
                  <img src='assets/img/clg.png'   style={{width:'25%'}}/>
                    <span data-purecounter-start={0} data-purecounter-end={1463} data-purecounter-duration={1} className="purecounter" />
                    <p><strong>Rated Among Best Colleges</strong></p>
                  <br />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
                  <div className="count-box">
                  <img src='assets/img/growth.png'   style={{width:'25%'}}/>
                    <span data-purecounter-start={0} data-purecounter-end={15} data-purecounter-duration={1} className="purecounter" />
                    <p><strong>All Rounded Growth</strong></p>
                  <br />
                  </div>
                </div>
              </div>
            </div>
          </section>{/* End Counts Section */}</div>
          
          {/* End Counts Section */}
                 {/* ======= Why Us Section ======= */}
                 <section id="why-us" className="why-us section-bg">
            <div className="container-fluid" data-aos="fade-up">
              <div className="row">
                <div className="col-lg-5 align-items-stretch video-box" style={{backgroundImage: 'url("assets/img/clg.jpeg")'}} data-aos="zoom-in" data-aos-delay={100}>
                 
                </div>
                <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch">
                  <div className="content">
                    {/* <h3>WELCOME TO <strong>Ramakrishna ITI </strong></h3> */}
                    <p>
                    Rama krishna  ITI conducts 3 trades of Job-oriented ITI courses. An ITI certificate provides quick and decent blue collar jobs to a vast majority. 
Since the Institute is located at the visakapatnam from where there is strong and constant demand for our ITI students. 
                    </p>
                    <br />
                    <p>
                    Our Institution has an experienced and dedicated Staffs, excellent Infrastructure such as well equipped laboratory, spacious class rooms,
 modernized computer lab with Internet facility, full-fledged indoor and outdoor sports activities with
 experienced coach, a large well stocked library etc.
                    </p>
                    <br />
                    <p>
                    All our ITI students have 100% placement. Most of our students who have completed their course successfully were working in public sectors
 like HAL, BEML, BHEL, BEL, DRDO, ISRO, many more Private Sectors, MNC Companies and also in Abroad.
                    </p>
                    <br />
                    <br />
                    
                  </div>
                  
                </div>
              </div>
            </div>
          </section>{/* End Why Us Section */}</div>
  )
}
