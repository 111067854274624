
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

export default function Header() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleNavLinkClick = () => {
    if (isMobileMenuOpen) {
      setMobileMenuOpen(false);
    }
  };

  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <header id="header" className={`d-flex align-items-center header-scrolled ${isScrolled ? 'fixed' : ''}`}>
      <div className="container d-flex align-items-center">
          <div className="logo me-auto">
            
          </div>
          <div
            id="navbar"
            className={`navbar order-last order-lg-0 ${isMobileMenuOpen ? 'navbar-mobile' : ''}`}
          >
            
          <ul>
            <li>
              <NavLink
                exact
                to="/"
                className="nav-link scrollto active hover-3"
                onClick={handleNavLinkClick}
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/about"
                className="nav-link scrollto  hover-3"
                onClick={handleNavLinkClick}
              >
                About
              </NavLink>
            </li>
            <li className="dropdown">
            <a  className=" hover-3"  onClick={handleDropdownToggle}>
            <span style={{cursor:'pointer'}}>Courses</span> 
            <i className="bi bi-caret-down-fill" />
          </a>
          <ul className={`SMN_effect-90 dropdown ${isDropdownOpen ? 'dropdown-active' : ''}`}>
            <li>
              <NavLink
                to="/Electrician"
                className="nav-link scrollto"
                onClick={handleNavLinkClick}
              >
                Electrician
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/Fitter"
                className="nav-link scrollto"
                onClick={handleNavLinkClick}
              >
                 Fitter
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/Welder"
                className="nav-link scrollto"
                onClick={handleNavLinkClick}
              >
                 Welder
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                to="/dropdown4"
                className="nav-link scrollto"
                onClick={handleNavLinkClick}
              >
                Drop Down 4
              </NavLink>
            </li> */}
          </ul>
        </li>

            <li>
              <NavLink
                to="/services"
                className="nav-link scrollto  hover-3"
                onClick={handleNavLinkClick}
              >
                Admissions
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/portfolio"
                className="nav-link scrollto  hover-3"
                onClick={handleNavLinkClick}
              >
                Our Recruiters
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact"
                className="nav-link scrollto  hover-3"
                onClick={handleNavLinkClick}
              >
                Contact
              </NavLink>
            </li>
            <li>
              <NavLink
                to="tel:7702252510"
                className="getstarted scrollto"
                onClick={handleNavLinkClick}
              >
               Enquire Now   <i class="bi bi-telephone-fill"></i>
              </NavLink>
            </li>
          </ul>
            <i className={`bi ${isMobileMenuOpen ? 'bi-x' : 'bi-list'} mobile-nav-toggle`} onClick={handleMobileMenuToggle} />
          </div>
          <div className="logo me-auto">
            
          </div>
        </div>
        
      </header>
    </div>
  );
}
